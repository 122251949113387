<template>
	<PublishedModalRoot>
		<template #title>
			{{ $t('builder.publishedModal.title') }}
		</template>
		<template #subtitle>
			{{ $t('builder.publishedModal.subtitle') }}
		</template>
	</PublishedModalRoot>
</template>

<script>
import PublishedModalRoot from './PublishedModalRoot.vue';

export default { components: { PublishedModalRoot } };
</script>
